import { defineStore } from 'pinia';

/**
 * The common Pinia store.
 */
export const useAccountStore = defineStore({
    id: 'account',

    state: () => ({
        /**
         * The listed accounts.
         */
        accounts: [] as Account[],

        /**
         * The current account, if set.
         */
        account: null as Account | null,

        /**
         * The account's slug error.
         */
        slugError: null as string | null,

        /**
         * Should account fields have validation displayed.
         */
        showValidation: false as boolean,
    }),

    getters: {
        /**
         * Are we operating in an account?
         *
         * @param state
         */
        inAccount: (state): boolean => state.account !== null,

        /**
         * The account's locale.
         *
         * @param state
         */
        locale: (state): string => {
            if (state.account !== null) {
                return state.account.locale;
            }

            return 'en_US';
        },

        /**
         * The current account's validation errors.
         */
        validationErrors: (state): AccountErrors => {
            const errors: AccountErrors = {} as AccountErrors;

            if (state.slugError !== null) {
                errors.slug = state.slugError;
            }

            // If validation should not be shown
            if (!state.showValidation) {
                // Stop there
                return errors;
            }

            // If no account is set
            if (!state.account) {
                // Stop there
                return errors;
            }

            if (!state.account.slug) {
                errors.slug = 'Enter a sign in URL';
            }

            // Validate default validity
            if (Object.keys(validateValidity(state.account.validity)).length) {
                errors.validity = validateValidity(state.account.validity);
            }

            // Validate monetary validity
            if (Object.keys(validateValidity(state.account.monetary_validity)).length) {
                errors.monetary_validity = validateValidity(state.account.monetary_validity);
            }

            return errors;
        },

        /**
         * Is the account in a valid state.
         */
        isValid(): boolean {
            return Object.keys(this.validationErrors).length === 0;
        },
    },

    actions: {
        /**
         * List accounts.
         */
        async index(): Promise<void> {
            const { data: accounts } = await apiGet('/accounts');

            this.accounts = accounts;
        },

        /**
         * Retrieve an account.
         */
        async retrieve(slug: string): Promise<Account> {
            // Retrieve the account
            const { data } = await apiGet(`/accounts/slug/${slug}`);

            return data;
        },

        /**
         * Update the account.
         */
        async update(): Promise<boolean> {
            const data: Account = this.account as Account;

            // Take a snapshot of the account before the update request
            const oldAccount: Account = this.account as Account;

            try {
                // Update the account
                await apiPatch(
                    `/accounts/${data.id}`,
                    {
                        name: data.name,
                        locale: data.locale,
                        currency_code: data.currency_code,
                        timezone: data.timezone,
                        slug: data.slug,
                        validity: data.validity,
                        monetary_validity: data.monetary_validity,
                        tax_percentage: data.tax_percentage,
                        monetary_tax_percentage: data.monetary_tax_percentage,
                        data_retention_months: data.data_retention_months,
                    },
                );

                // If the update succeeds, the slug is available
                this.slugError = null;

                // If has multiple currencies is true
                if (data.has_multiple_currencies) {
                    // Activate the account's has multiple currencies
                    await apiPost(`/accounts/${data.id}/multiple-currencies/activate`);
                } else {
                    // Otherwise, deactivate the account's has multiple currencies
                    await apiPost(`/accounts/${data.id}/multiple-currencies/deactivate`);
                }

                // If has multiple stores is true
                if (data.has_multiple_stores) {
                    // Activate the account's has multiple stores
                    await apiPost(`/accounts/${data.id}/multiple-stores/activate`);
                } else {
                    // Otherwise, deactivate the account's has multiple stores
                    await apiPost(`/accounts/${data.id}/multiple-stores/deactivate`);
                }

                // Show a success notification
                notifySuccess('Settings updated', 2000);
            } catch (error: any) {
                // Revert the account to its state before the response updated it
                this.account = oldAccount;

                // If the request returns 422
                if (error.statusCode === 422) {
                    // If there is a slug error
                    if (error.data && error.data.errors && 'slug' in error.data.errors) {
                        // If the error is a formatting error
                        if (error.data.errors.slug.includes('The slug format is invalid.')) {
                            // The slug format is invalid
                            this.slugError = 'The URL format is invalid';

                            return false;
                        }

                        // If the error is a uniqueness error
                        if (error.data.errors.slug.includes('The slug has already been taken.')) {
                            // The slug is taken
                            this.slugError = 'This URL is taken. Enter another sign in URL.';

                            return false;
                        }
                    }
                }

                throw error;
            }

            return true;
        },

        /**
         * Check an account slug's availability.
         */
        async checkSlugAvailability(slug: string): Promise<void> {
            // Take a snapshot of the account before the check slug availability request
            const oldAccount: Account = this.account as Account;

            try {
                // Check the slug's availability
                await apiPost(
                    `/accounts/${(this.account as Account).id}/slug`,
                    { slug },
                );

                // If it returns 200, the slug is available
                this.slugError = null;
            } catch (error: any) {
                // If the request returns 422
                if (error.statusCode === 422) {
                    // If there is a slug error
                    if (error.data && error.data.errors && 'slug' in error.data.errors) {
                        // If the error is a formatting error
                        if (error.data.errors.slug.includes('The slug format is invalid.')) {
                            // The slug format is invalid
                            this.slugError = 'The URL format is invalid';

                            return;
                        }

                        // If the error is a uniqueness error
                        if (error.data.errors.slug.includes('The slug has already been taken.')) {
                            // The slug is taken
                            this.slugError = 'This URL is taken. Enter another sign in URL.';

                            return;
                        }
                    }
                }

                throw error;
            } finally {
                // Revert the account to its state before the response updated it
                this.account = oldAccount;
            }
        },
    },
});
