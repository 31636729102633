// Routes that are external to the dashboard
export default [
    '/auth/login',
    '/auth/login/:accountSlug()',
    '/auth/register',
    '/auth/request-password-reset',
    '/auth/request-password-reset-confirmation',
    '/auth/reset-password',
    '/auth/reset-password-confirmation',
    '/auth/invalid-password-reset-token',
    '/auth/verify-email/:userId()/:hash()',
    '/invitation/invalid',
    '/invitation/:invitationId()',
] as string[];
