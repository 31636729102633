import { defineStore } from 'pinia';
import { apiGet, apiPost } from '~/utils/requests';

/**
 * The auth Pinia store.
 */
export const useAuthStore = defineStore({
    id: 'auth',

    state: () => ({
        /**
         * The currently authenticated user.
         */
        user: null as AuthUser | null,

        /**
         * The user's entered current password.
         */
        currentPassword: '' as string,

        /**
         * Is the entered current password correct.
         */
        currentPasswordCorrect: false as boolean,

        /**
         * Is the entered email available.
         */
        emailAvailable: false as boolean,

        /**
         * The new account ID header if the account should be changed.
         */
        newAccountIdHeader: null as string | null,
    }),

    getters: {
        /**
         * Is a user logged in.
         *
         * @param state
         */
        loggedIn: (state): boolean => state.user !== null,

        /**
         * The authenticated user's role.
         *
         * @param state
         */
        role: (state): string => {
            // If no user is set
            if (state.user === null) {
                // Return an empty string
                return '';
            }

            // If the user is an admin user
            if (state.user.is_admin) {
                // Return admin
                return 'admin';
            }

            // If the user has a permission belonging to only admin users
            if ((state.user as AuthUser).permissions.includes('manage_catalogue')) {
                // Return admin
                return 'admin';
            }

            // Otherwise, return sell and redeem
            return 'sell_and_redeem';
        },
    },

    actions: {
        /**
         * Check if there is a user logged in the session with the API.
         */
        async check(): Promise<boolean> {
            try {
                // Send the auth check request
                await apiGet('/auth/check');

                // Return the value of loggedIn
                return this.loggedIn;
            } catch (error: any) {
                // If the request returns 401
                if (error.statusCode === 401) {
                    // Return the user as not logged in
                    return false;
                }

                throw error;
            }
        },

        /**
         * Check if there is a user logged in the session with the API including redeem location.
         */
        async checkRedeemLocation(): Promise<boolean> {
            try {
                // Send the auth check request
                await apiGet('/auth/check/redeem-location');

                // Return the value of loggedIn
                return this.loggedIn;
            } catch (error: any) {
                // If the request returns 401
                if (error.statusCode === 401) {
                    // Return the user as not logged in
                    return false;
                }

                throw error;
            }
        },

        /**
         * Log a user in.
         *
         * @param credentials
         */
        async login(credentials: LoginCredentials): Promise<boolean> {
            try {
                // Initialise the API session using the Sanctum magic route
                await apiGet('/sanctum/csrf-cookie');

                // Send the login request
                const { data: user } = await apiPost('/auth/login', credentials);

                // Set the user
                this.user = user;

                return true;
            } catch (error: any) {
                // If the request returns 401
                if (error.statusCode === 401) {
                    // Return the user as not logged in
                    return false;
                }

                throw error;
            }
        },

        /**
         * Log a user in to an account.
         */
        async accountLogin(credentials: LoginCredentials, accountId: string): Promise<boolean> {
            try {
                // Initialise the API session using the Sanctum magic route
                await apiGet('/sanctum/csrf-cookie');

                let user: AuthUser;

                // If the user identifier contains an @
                if (credentials.email.includes('@')) {
                    // Send the email login request
                    const data = await apiPost(
                        '/auth/login',
                        {
                            ...credentials,
                            account_id: accountId,
                        },
                    );

                    user = data.data;
                } else {
                    // Send the username login request
                    const data = await apiPost(
                        '/auth/login/username',
                        {
                            username: credentials.email,
                            password: credentials.password,
                            account_id: accountId,
                        },
                    );

                    user = data.data;
                }

                // Set the user
                this.user = user;

                return true;
            } catch (error: any) {
                // If the request returns 401
                if (error.statusCode === 401) {
                    // Return the user as not logged in
                    return false;
                }

                throw error;
            }
        },

        /**
         * Update the user's email.
         */
        async updateEmail(email: string): Promise<void> {
            try {
                this.currentPasswordCorrect = true;
                this.emailAvailable = true;

                // Send the update request
                await apiPatch(
                    '/auth',
                    {
                        current_password: this.currentPassword,
                        email,
                    },
                );
            } catch (error: any) {
                try {
                    // If the current password is incorrect
                    if ('current_password' in error.data.errors) {
                        if (error.data.errors.current_password[0] === 'validation.current_password') {
                            // Mark the current password as invalid
                            this.currentPasswordCorrect = false;

                            return;
                        }
                    }

                    // If the email is already in use
                    if ('email' in error.data.errors) {
                        if (error.data.errors.email[0] === 'The email has already been taken.') {
                            // Mark the new email as unavailable
                            this.emailAvailable = false;

                            return;
                        }
                    }

                    throw error;
                } catch (error: any) {
                    throw error;
                }
            }
        },

        /**
         * Update the user's password.
         */
        async updatePassword(password: string, passwordConfirmation: string): Promise<void> {
            try {
                this.currentPasswordCorrect = true;

                // Send the update request
                await apiPatch(
                    '/auth',
                    {
                        current_password: this.currentPassword,
                        password,
                        password_confirmation: passwordConfirmation,
                    },
                );
            } catch (error: any) {
                try {
                    // If the current password is incorrect
                    if ('current_password' in error.data.errors) {
                        if (error.data.errors.current_password[0] === 'validation.current_password') {
                            // Mark the current password as invalid
                            this.currentPasswordCorrect = false;

                            return;
                        }
                    }

                    throw error;
                } catch (error: any) {
                    throw error;
                }
            }
        },

        /**
         * Send the auth check request with an account ID header to switch account.
         */
        async switchAccount(accountId: string): Promise<void> {
            // Set the new account ID header
            this.newAccountIdHeader = accountId;

            // Send the auth check request
            await apiGet('/auth/check');
        },

        /**
         * Request a password reset email.
         */
        async requestPasswordResetEmail(email: string): Promise<void> {
            // Request a password reset email
            await apiPost(
                '/auth/request-password-reset',
                { email },
            );
        },

        /**
         * Reset the user's password.
         */
        async resetPassword(credentials: ResetPasswordCredentials): Promise<boolean> {
            try {
                // Reset password
                await apiPost(
                    '/auth/reset-password',
                    credentials,
                );
            } catch (error: any) {
                // If the request returns 422
                if (error.statusCode === 422) {
                    const errorMessages: string[] = [
                        "We can't find a user with that email address.",
                        'This password reset token is invalid.',
                    ];

                    // If the user was not found for the given email, a token doesn't exist for the
                    // user, the token is invalid, or the token is expired
                    if (errorMessages.includes(error.data.errors.password[0])) {
                        // Show the invalid password reset token page
                        return false;
                    }
                }

                throw error;
            }

            return true;
        },

        /**
         * Verify a user's email address.
         */
        async verifyEmail(userId: string, hash: string): Promise<string | boolean> {
            try {
                // Send the verify email request
                const { data } = await apiGet(`/auth/verify-email/${userId}/${hash}`);

                return data.email;
            } catch (error: any) {
                // If the request returns 403
                if (error.statusCode === 403) {
                    // Show the verification failed page
                    return false;
                }

                // If the request returns 404
                if (error.statusCode === 404) {
                    if (error.data.message.includes('No query results for model')) {
                        // Show the verification failed page
                        return false;
                    }
                }

                throw error;
            }
        },

        /**
         * Log the user out.
         */
        async logout(): Promise<void> {
            // Determine whether the authenticated user is a username user
            const isUsernameUser: boolean = (this.user as AuthUser).username !== null;

            let accountSlug: string = '';

            // If the user is a username user
            if (isUsernameUser) {
                // Set the current account's slug
                accountSlug = (useAccountStore().account as Account).slug;
            }

            // Send the logout request
            await apiPost('/auth/logout');

            // Reset the auth state
            this.$reset();

            // If the user was a username user
            if (isUsernameUser) {
                // Redirect to the account login page
                await navigateTo({
                    name: 'auth-login-accountSlug',
                    params: { accountSlug },
                    replace: true,
                });
            } else {
                // Redirect to the login page
                await navigateTo({
                    name: 'auth-login',
                    replace: true,
                });
            }
        },
    },
});
