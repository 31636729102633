import { defineStore } from 'pinia';

/**
 * The terminal Pinia store.
 */
export const useTerminalStore = defineStore({
    id: 'terminal',

    state: () => ({
        /**
         * The current redeem location.
         */
        redeemLocation: null as RedeemLocation | null,

        /**
         * The redeemables to be redeemed.
         */
        redeemables: [] as Redeemable[],

        /**
         * The number of redeemables that have been redeemed.
         */
        redemptionCount: null as number | null,

        /**
         * The reference of the voucher whose redeemables have been redeemed.
         */
        voucherReference: null as string | null,

        /**
         * The "back to" button option.
         */
        backToButton: null as { destination: string, text: string } | null,
    }),

    getters: {
        /**
         * The total number of redeemables.
         */
        redeemablesCount: (state): number => state.redeemables.length,

        /**
         * The valid redeemables.
         */
        validRedeemables: (state): Redeemable[] => state.redeemables
            .filter((redeemable: Redeemable): boolean => redeemable.invalid_reason === null),
    },

    actions: {
        /**
         * Redeem redeemables.
         */
        async redeem(): Promise<boolean> {
            try {
                await apiPost(
                    '/terminal/redeemables/redeem',
                    {
                        redeemables: (this.validRedeemables as Redeemable[])
                            .map((redeemable: Redeemable): {
                                id: string,
                                value?: number
                            } => {
                                const redeemableData: {
                                    id: string,
                                    value?: number,
                                } = { id: redeemable.id };

                                if (redeemable.type === 'monetary') {
                                    redeemableData.value = redeemable.redemption_monetary_value;
                                }

                                return redeemableData;
                            }),
                    },
                );

                return true;
            } catch (error: any) {
                // If the request returns 403
                if (error.statusCode === 403) {
                    // Return false to indicate the redemption failed
                    return false;
                }

                throw error;
            }
        },

        /**
         * Retrieve a redeemable.
         */
        async retrieveRedeemable(redeemableId: string): Promise<void> {
            // Retrieve the redeemable
            const { data } = await apiGet(`/terminal/redeemables/${redeemableId}`);

            if ((data.type as string) === 'addon') {
                data.type = 'enhancement';
            }

            // Set the redeemable in the Pinia store
            this.redeemables = ([data]);
        },
    },
});
