/**
 * Show a success notification.
 */
export const notifySuccess = (title: string, timeout: number, description?: string): void => {
    useToast().add({
        color: 'green',
        icon: 'i-heroicons-check-circle',
        title,
        description,
        timeout,
    });
};
