/**
 * Redirect to the login page if the domain is the login shortcut domain.
 */
export default defineNuxtRouteMiddleware(async (to) => {
    const hostnameStartsWithLogin: boolean = window.location.hostname.startsWith('login.');

    // If the hostname does not begin with "login."
    if (!hostnameStartsWithLogin) {
        // Do nothing
        return;
    }

    const path: string = window.location.pathname;

    // If the route is not "login.ROOT_DOMAIN/{slug}"
    if (path.substring(1).includes('/')) {
        // Redirect to "dashboard.ROOT_DOMAIN/*"
        window.location.replace(`https://dashboard.${rootDomain()}${path}`);
    }

    // Redirect to "dashboard.ROOT_DOMAIN/auth/login/{slug}"
    window.location.replace(`https://dashboard.${rootDomain()}/auth/login${path}`);
});
