import { type FetchOptions, type FetchResponse } from 'ofetch';
import { type TypedInternalResponse } from 'nitropack';
import { apiUrl, isExternalRoute } from '~/utils/routes';

/**
 * Return the decoded token cookie for use in $fetch requests.
 */
const decodedToken = (): string => {
    // Extract the token cookie from the cookies string
    const tokenCookie = document.cookie
        .split('; ')
        .find((row) => row.startsWith('XSRF-TOKEN='))
        ?.split('=')[1];

    // Decode the token
    // @ts-ignore
    return decodeURIComponent(tokenCookie);
};

/**
 * Set user data in the Pinia store using response data.
 *
 * @param response
 */
const setAuthData = (response: FetchResponse<any> | FetchResponse<ResponseType>): void => {
    // Skip the following checks for 204 responses as they have no content
    if (response.status === 204) {
        return;
    }

    // Skip the following checks for non JSON responses as they never contain a user object
    if (response.headers.get('content-type') !== 'application/json') {
        return;
    }

    const auth = useAuthStore();

    if (response._data.user) {
        // Set the user from the response if present
        auth.user = response._data.user;
    } else {
        // Otherwise we are not logged in
        auth.user = null;

        // If the current route is not an external route
        if (!isExternalRoute()) {
            // Redirect to the login page with the current route as redirect
            navigateTo({
                name: 'auth-login',
                replace: true,
                query: { redirect: useRoute().fullPath },
            });
        }
    }
};

/**
 * Set partner data in the common Pinia store using response data.
 *
 * @param response
 */
const setPartnerData = (response: FetchResponse<any> | FetchResponse<ResponseType>): void => {
    // Skip the following checks for 204 responses as they have no content
    if (response.status === 204) {
        return;
    }

    // Skip the following checks for non JSON responses as they never contain a partner object
    if (response.headers.get('content-type') !== 'application/json') {
        return;
    }

    // If the response contains a partner object
    if (response._data.partner) {
        // Update the partner set on the common Pinia store
        useCommonStore().partner = response._data.partner;
    }
};

/**
 * Set account data in the Pinia store using response data.
 *
 * @param response
 */
const setAccountData = (response: FetchResponse<any> | FetchResponse<ResponseType>): void => {
    // Skip the following checks for 204 responses as they have no content
    if (response.status === 204) {
        return;
    }

    // Skip the following checks for non JSON responses as they never contain an account object
    if (response.headers.get('content-type') !== 'application/json') {
        return;
    }

    const accountsStore = useAccountStore();

    if (response._data.account) {
        // Set the account from the response if present
        accountsStore.account = response._data.account;
    } else {
        // Otherwise there is no account
        accountsStore.account = null;
    }
};

/**
 * Set redeem location data in the Pinia store using response data.
 *
 * @param response
 */
const setRedeemLocationData = (response: FetchResponse<any> | FetchResponse<ResponseType>): void => {
    // Skip the following checks for 204 responses as they have no content
    if (response.status === 204) {
        return;
    }

    // Skip the following checks for non JSON responses as they never contain a user object
    if (response.headers.get('content-type') !== 'application/json') {
        return;
    }

    const terminalStore = useTerminalStore();

    if (response._data.redeem_location) {
        // Set the redeem location from the response if present
        terminalStore.redeemLocation = response._data.redeem_location;
    } else {
        // Extract the API request path
        const path = response.url.replace(apiUrl(), '');

        // If the path does not begin with "/terminal"
        if (!path.startsWith('/terminal')) {
            // Leave the redeem location unchanged
            return;
        }

        // Otherwise there is no redeem location
        terminalStore.redeemLocation = null;
    }
};

/**
 * Attach Account-ID and Redeem-Location-ID headers to requests.
 *
 * @param options
 */
const setHeaders = (options: FetchOptions): FetchOptions => {
    const accountsStore = useAccountStore();
    const terminalStore = useTerminalStore();

    options.headers = options.headers ?? {};

    // If an account is set
    if (accountsStore.account) {
        options.headers = {
            ...options.headers,
            // Attach the Account-ID header to requests
            // @ts-ignore
            'Account-ID': useAuthStore().newAccountIdHeader ?? accountsStore.account.id,
        };
    }

    // If a redeem location is set
    if (terminalStore.redeemLocation) {
        options.headers = {
            ...options.headers,
            // Attach the Redeem-Location-ID header to requests
            // @ts-ignore
            'Redeem-Location-ID': terminalStore.redeemLocation.id,
        };
    }

    return options;
};

/**
 * Construct an API request using the API base URL environment variable.
 *
 * @param path
 * @param method
 * @param body
 * @param config
 * @param headers
 */
const apiRequest = async (
    path: string,
    method: 'GET' | 'PATCH' | 'POST' | 'DELETE',
    body: Object | null,
    config = {},
    headers = {},
): Promise<TypedInternalResponse<any>> => {
    // Normalise the path
    const normalisedPath = normalisePath(path);

    try {
        // Execute the request
        return await $fetch(`${apiUrl()}${normalisedPath}`, {
            method,
            body,
            headers: {
                // Specifying response type
                Accept: 'application/json',
                // Using the decoded token
                'X-XSRF-TOKEN': decodedToken(),
                ...headers,
            },
            // Including cookies
            credentials: 'include',
            // With Account-ID header where appropriate
            async onRequest({ options }) {
                options = setHeaders(options);
            },
            // Checking for partner, user, account, and redeem location data in the response
            async onResponse({ response }) {
                setPartnerData(response);
                setAuthData(response);
                setAccountData(response);
                setRedeemLocationData(response);
            },
            ...config,
        });
    } catch (error: any) {
        // If the request returns 401
        if (error.statusCode === 401) {
            // The user is not logged in
            useAuthStore().user = null;

            // If the current route is not an external route
            if (!isExternalRoute()) {
                // Redirect to the login page with the current route as redirect
                await navigateTo({
                    name: 'auth-login',
                    replace: true,
                    query: { redirect: useRoute().fullPath },
                });
            }

            // Otherwise, throw the error as normal
            throw error;
        } else {
            throw error;
        }
    }
};

/**
 * Construct an API GET request using the API base URL environment variable.
 *
 * @param path
 * @param config
 */
export const apiGet = async (
    path: string,
    config = {},
): Promise<TypedInternalResponse<any>> => apiRequest(path, 'GET', null, config);

/**
 * Construct an API POST request using the API base URL environment variable.
 *
 * @param path
 * @param body
 * @param config
 * @param headers
 */
export const apiPost = async (
    path: string,
    body = {},
    config = {},
    headers = {},
): Promise<TypedInternalResponse<any>> => apiRequest(path, 'POST', body, config, headers);

/**
 * Construct an API PATCH request using the API base URL environment variable.
 *
 * @param path
 * @param body
 * @param config
 */
export const apiPatch = async (
    path: string,
    body = {},
    config = {},
): Promise<TypedInternalResponse<any>> => apiRequest(path, 'PATCH', body, config);

/**
 * Construct an API DELETE request using the API base URL environment variable.
 *
 * @param path
 * @param config
 */
export const apiDelete = async (
    path: string,
    config = {},
): Promise<TypedInternalResponse<any>> => apiRequest(path, 'DELETE', null, config);
