import { defineStore, type Store as PiniaStore } from 'pinia';

/**
 * The common Pinia store.
 */
export const useCommonStore = defineStore({
    id: 'common',

    state: () => ({
        /**
         * The current partner.
         */
        partner: {
            name: '',
            store_root_domain: '',
            seller_email_domain: '',
            logo_url: '',
            favicon_url: '',
        } as Partner,

        /**
         * The current Pinia store, if set.
         */
        piniaStore: null as PiniaStore | null,

        /**
         * The submenu, if set.
         */
        submenu: null as SubmenuLink[] | null,

        /**
         * The model the overflow menu relates to, if set.
         */
        overflowMenuModel: null as Model | null,

        /**
         * The table meta information.
         */
        tableMeta: null as TableMeta | null,

        /**
         * Is the table loading.
         */
        tableIsLoading: false as boolean,
    }),

    actions: {
        /**
         * Retrieve the partner.
         */
        async retrievePartner() {
            // Send the retrieve partner request
            const { data } = await apiGet('/partner');

            // Set the partner on the Pinia store
            this.partner = data;
        },

        /**
         * Hide the submenu.
         */
        hideSubmenu(): void {
            this.submenu = null;
        },
    },
});
