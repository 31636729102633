// Terminal routes
export default [
    '/terminal',
    '/terminal/redeem',
    '/terminal/redeem/confirmation',
    '/terminal/redeem/:reference()',
    '/terminal/redeem/voucher/:reference()',
    '/terminal/redeem/voucher/:reference()/:redeemableId()',
    '/terminal/auth/settings',
] as string[];
