<script setup lang="ts">
import { isExternalRoute } from '~/utils/routes';
import terminalRoutes from '~/utils/terminalRoutes';

/* Computed variables */

/**
 * Is the current route external.
 */
const isExternal: ComputedRef<boolean> = computed((): boolean => isExternalRoute());

/**
 * Is the current route a terminal route.
 */
const isTerminal: ComputedRef<boolean> = computed((): boolean => {
    // If the current route matches a defined route
    if (useRoute().matched[0]) {
        // Determine if the current route is a terminal route
        return terminalRoutes.includes(useRoute().matched[0].path);
    }

    return false;
});

/* Component events */

onMounted(async (): Promise<void> => {
    // Retrieve the partner's details
    await useCommonStore().retrievePartner();

    // Set the page meta title and favicon
    useHead({
        titleTemplate: `%s - ${useCommonStore().partner.name}`,
        link: [{
            rel: 'icon',
            href: useCommonStore().partner.favicon_url,
        }],
    });
});
</script>

<template>
    <NuxtLayout :name="isExternal ? 'external' : isTerminal ? 'terminal' : 'sidebar'">
        <NuxtPage/>
    </NuxtLayout>

    <UNotifications>
        <template #title="{ title }">
            <span v-html="title" class="notification-title"/>
        </template>

        <template #description="{ description }">
            <span v-html="description" class="notification-description"/>
        </template>
    </UNotifications>
</template>
