import { default as invalid_45password_45reset_45tokenHvgzP2f6lNMeta } from "/home/runner/work/dashboard/dashboard/pages/auth/invalid-password-reset-token.vue?macro=true";
import { default as _91accountSlug_93KvDaMkjhWUMeta } from "/home/runner/work/dashboard/dashboard/pages/auth/login/[accountSlug].vue?macro=true";
import { default as indexJC5ST6ghsPMeta } from "/home/runner/work/dashboard/dashboard/pages/auth/login/index.vue?macro=true";
import { default as request_45password_45reset_45confirmationMtOP7CIVWwMeta } from "/home/runner/work/dashboard/dashboard/pages/auth/request-password-reset-confirmation.vue?macro=true";
import { default as request_45password_45reset4DxUe558E2Meta } from "/home/runner/work/dashboard/dashboard/pages/auth/request-password-reset.vue?macro=true";
import { default as reset_45password_45confirmationRG2RExxdlkMeta } from "/home/runner/work/dashboard/dashboard/pages/auth/reset-password-confirmation.vue?macro=true";
import { default as reset_45passwordBI6yvYngksMeta } from "/home/runner/work/dashboard/dashboard/pages/auth/reset-password.vue?macro=true";
import { default as settingsdy2kRmbIVeMeta } from "/home/runner/work/dashboard/dashboard/pages/auth/settings.vue?macro=true";
import { default as _91hash_93wINMVrUdXOMeta } from "/home/runner/work/dashboard/dashboard/pages/auth/verify-email/[userId]/[hash].vue?macro=true";
import { default as _91categoryId_93MUYEY2IL2kMeta } from "/home/runner/work/dashboard/dashboard/pages/categories/[categoryId].vue?macro=true";
import { default as _91salesChannelId_93jcdNfLhyHQMeta } from "/home/runner/work/dashboard/dashboard/pages/categories/create/[salesChannelId].vue?macro=true";
import { default as indexLbaC5b4OauMeta } from "/home/runner/work/dashboard/dashboard/pages/categories/index.vue?macro=true";
import { default as categoriesV6Lx1InbDKMeta } from "/home/runner/work/dashboard/dashboard/pages/categories.vue?macro=true";
import { default as codesi6k5OdZVGpMeta } from "/home/runner/work/dashboard/dashboard/pages/discounts/[discountId]/codes.vue?macro=true";
import { default as indexwCJb95loJyMeta } from "/home/runner/work/dashboard/dashboard/pages/discounts/[discountId]/index.vue?macro=true";
import { default as createofupGenh4oMeta } from "/home/runner/work/dashboard/dashboard/pages/discounts/create.vue?macro=true";
import { default as index1iX6RBJy9wMeta } from "/home/runner/work/dashboard/dashboard/pages/discounts/index.vue?macro=true";
import { default as _91enhancementId_93N69xBqolMFMeta } from "/home/runner/work/dashboard/dashboard/pages/enhancements/[enhancementId].vue?macro=true";
import { default as createDLGLkX4NdPMeta } from "/home/runner/work/dashboard/dashboard/pages/enhancements/create.vue?macro=true";
import { default as indexyqkdGS8dntMeta } from "/home/runner/work/dashboard/dashboard/pages/enhancements/index.vue?macro=true";
import { default as enhancements3yKSJ3BV9IMeta } from "/home/runner/work/dashboard/dashboard/pages/enhancements.vue?macro=true";
import { default as indexMm2ZM7LfDiMeta } from "/home/runner/work/dashboard/dashboard/pages/index.vue?macro=true";
import { default as _91invitationId_93PhL1nSwbDuMeta } from "/home/runner/work/dashboard/dashboard/pages/invitation/[invitationId].vue?macro=true";
import { default as invalidcxFIIDEfnsMeta } from "/home/runner/work/dashboard/dashboard/pages/invitation/invalid.vue?macro=true";
import { default as _91orderId_93Dd4FVazpyRMeta } from "/home/runner/work/dashboard/dashboard/pages/orders/[orderId].vue?macro=true";
import { default as indexZTkQzWDGkIMeta } from "/home/runner/work/dashboard/dashboard/pages/orders/index.vue?macro=true";
import { default as orderspokBprXfafMeta } from "/home/runner/work/dashboard/dashboard/pages/orders.vue?macro=true";
import { default as indexXu9adEzwKOMeta } from "/home/runner/work/dashboard/dashboard/pages/products/[productId]/index.vue?macro=true";
import { default as _91variantId_93SzwQXnDlQ4Meta } from "/home/runner/work/dashboard/dashboard/pages/products/[productId]/variants/[variantId].vue?macro=true";
import { default as createKYH9TJufenMeta } from "/home/runner/work/dashboard/dashboard/pages/products/[productId]/variants/create.vue?macro=true";
import { default as _91type_935SaHedTheOMeta } from "/home/runner/work/dashboard/dashboard/pages/products/create/[type].vue?macro=true";
import { default as createdPWMzbJzG6Meta } from "/home/runner/work/dashboard/dashboard/pages/products/create/variants/create.vue?macro=true";
import { default as indexX9E3n1vsapMeta } from "/home/runner/work/dashboard/dashboard/pages/products/index.vue?macro=true";
import { default as productsvUwbW9DgHqMeta } from "/home/runner/work/dashboard/dashboard/pages/products.vue?macro=true";
import { default as indexABfqqO6wyGMeta } from "/home/runner/work/dashboard/dashboard/pages/reports/index.vue?macro=true";
import { default as reportshCmlfPrGB1Meta } from "/home/runner/work/dashboard/dashboard/pages/reports.vue?macro=true";
import { default as billingMRFCTtWjZ3Meta } from "/home/runner/work/dashboard/dashboard/pages/settings/billing.vue?macro=true";
import { default as _91hostname_93QOBGLnSTZ9Meta } from "/home/runner/work/dashboard/dashboard/pages/settings/email-domains/[hostname].vue?macro=true";
import { default as indexvP7FMhrJgqMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/email-domains/index.vue?macro=true";
import { default as email_45domains6atHaIv86FMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/email-domains.vue?macro=true";
import { default as generalzRHj1DMZGUMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/general.vue?macro=true";
import { default as indexgLKkuUg2x3Meta } from "/home/runner/work/dashboard/dashboard/pages/settings/index.vue?macro=true";
import { default as paypalZZkuduttlvMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/payment-gateways/connect/paypal.vue?macro=true";
import { default as stripeJA7eJVskJMMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/payment-gateways/connect/stripe.vue?macro=true";
import { default as indexzXE2qsy69rMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/payment-gateways/index.vue?macro=true";
import { default as _91redeemLocationId_93yKsWNCbCaPMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/redeem-locations/[redeemLocationId].vue?macro=true";
import { default as createrEW6HHLqdKMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/redeem-locations/create.vue?macro=true";
import { default as indexkwe8VZKJSxMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/redeem-locations/index.vue?macro=true";
import { default as redeem_45locationsawD0PJp3TmMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/redeem-locations.vue?macro=true";
import { default as _91userId_93xvSzuW5bUoMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/users/[userId].vue?macro=true";
import { default as emailBXfCz7IIqwMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/users/create/email.vue?macro=true";
import { default as usernameAFdgxoXa5kMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/users/create/username.vue?macro=true";
import { default as indexgwtn5cfBPIMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/users/index.vue?macro=true";
import { default as _91invitationId_93joJAxk8iObMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/users/invitations/[invitationId].vue?macro=true";
import { default as indexGaM0PSVv1bMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/users/invitations/index.vue?macro=true";
import { default as userscBFy1d0MemMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/users.vue?macro=true";
import { default as settingslZuwzuWYJ0Meta } from "/home/runner/work/dashboard/dashboard/pages/settings.vue?macro=true";
import { default as _91page_93Ai3wtoRJpQMeta } from "/home/runner/work/dashboard/dashboard/pages/stores/[salesChannelId]/emails/[page].vue?macro=true";
import { default as indexvN9O1GV0KqMeta } from "/home/runner/work/dashboard/dashboard/pages/stores/[salesChannelId]/emails/index.vue?macro=true";
import { default as faq5kWEiV8EKiMeta } from "/home/runner/work/dashboard/dashboard/pages/stores/[salesChannelId]/faq.vue?macro=true";
import { default as indexN3mLogBYmaMeta } from "/home/runner/work/dashboard/dashboard/pages/stores/[salesChannelId]/index.vue?macro=true";
import { default as privacyf9pEyNN8Z1Meta } from "/home/runner/work/dashboard/dashboard/pages/stores/[salesChannelId]/privacy.vue?macro=true";
import { default as termsFdakOLnHINMeta } from "/home/runner/work/dashboard/dashboard/pages/stores/[salesChannelId]/terms.vue?macro=true";
import { default as _91page_938roXampeqIMeta } from "/home/runner/work/dashboard/dashboard/pages/stores/create/[type]/emails/[page].vue?macro=true";
import { default as indexCo4HWlaguYMeta } from "/home/runner/work/dashboard/dashboard/pages/stores/create/[type]/emails/index.vue?macro=true";
import { default as faqHWj8UmEqT3Meta } from "/home/runner/work/dashboard/dashboard/pages/stores/create/[type]/faq.vue?macro=true";
import { default as indexTEH9zmYUhtMeta } from "/home/runner/work/dashboard/dashboard/pages/stores/create/[type]/index.vue?macro=true";
import { default as privacyByP9mSA6KXMeta } from "/home/runner/work/dashboard/dashboard/pages/stores/create/[type]/privacy.vue?macro=true";
import { default as termsaUb4XBqvwiMeta } from "/home/runner/work/dashboard/dashboard/pages/stores/create/[type]/terms.vue?macro=true";
import { default as indexgwyxZtsxIHMeta } from "/home/runner/work/dashboard/dashboard/pages/stores/index.vue?macro=true";
import { default as interstitialmvRZM7J5BAMeta } from "/home/runner/work/dashboard/dashboard/pages/stores/interstitial.vue?macro=true";
import { default as storesmaIhalQqooMeta } from "/home/runner/work/dashboard/dashboard/pages/stores.vue?macro=true";
import { default as settingsOaNJChXSSfMeta } from "/home/runner/work/dashboard/dashboard/pages/terminal/auth/settings.vue?macro=true";
import { default as indexr0ZYRS1BiLMeta } from "/home/runner/work/dashboard/dashboard/pages/terminal/index.vue?macro=true";
import { default as _91reference_939tx0bUJDl4Meta } from "/home/runner/work/dashboard/dashboard/pages/terminal/redeem/[reference].vue?macro=true";
import { default as confirmationugEPFNdj5fMeta } from "/home/runner/work/dashboard/dashboard/pages/terminal/redeem/confirmation.vue?macro=true";
import { default as indexLSyr03I8CQMeta } from "/home/runner/work/dashboard/dashboard/pages/terminal/redeem/index.vue?macro=true";
import { default as _91redeemableId_93Wa2AUKs3QtMeta } from "/home/runner/work/dashboard/dashboard/pages/terminal/redeem/voucher/[reference]/[redeemableId].vue?macro=true";
import { default as indexCKqzUDBLwKMeta } from "/home/runner/work/dashboard/dashboard/pages/terminal/redeem/voucher/[reference]/index.vue?macro=true";
export default [
  {
    name: invalid_45password_45reset_45tokenHvgzP2f6lNMeta?.name ?? "auth-invalid-password-reset-token",
    path: invalid_45password_45reset_45tokenHvgzP2f6lNMeta?.path ?? "/auth/invalid-password-reset-token",
    meta: invalid_45password_45reset_45tokenHvgzP2f6lNMeta || {},
    alias: invalid_45password_45reset_45tokenHvgzP2f6lNMeta?.alias || [],
    redirect: invalid_45password_45reset_45tokenHvgzP2f6lNMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/auth/invalid-password-reset-token.vue").then(m => m.default || m)
  },
  {
    name: _91accountSlug_93KvDaMkjhWUMeta?.name ?? "auth-login-accountSlug",
    path: _91accountSlug_93KvDaMkjhWUMeta?.path ?? "/auth/login/:accountSlug()",
    meta: _91accountSlug_93KvDaMkjhWUMeta || {},
    alias: _91accountSlug_93KvDaMkjhWUMeta?.alias || [],
    redirect: _91accountSlug_93KvDaMkjhWUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/auth/login/[accountSlug].vue").then(m => m.default || m)
  },
  {
    name: indexJC5ST6ghsPMeta?.name ?? "auth-login",
    path: indexJC5ST6ghsPMeta?.path ?? "/auth/login",
    meta: indexJC5ST6ghsPMeta || {},
    alias: indexJC5ST6ghsPMeta?.alias || [],
    redirect: indexJC5ST6ghsPMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/auth/login/index.vue").then(m => m.default || m)
  },
  {
    name: request_45password_45reset_45confirmationMtOP7CIVWwMeta?.name ?? "auth-request-password-reset-confirmation",
    path: request_45password_45reset_45confirmationMtOP7CIVWwMeta?.path ?? "/auth/request-password-reset-confirmation",
    meta: request_45password_45reset_45confirmationMtOP7CIVWwMeta || {},
    alias: request_45password_45reset_45confirmationMtOP7CIVWwMeta?.alias || [],
    redirect: request_45password_45reset_45confirmationMtOP7CIVWwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/auth/request-password-reset-confirmation.vue").then(m => m.default || m)
  },
  {
    name: request_45password_45reset4DxUe558E2Meta?.name ?? "auth-request-password-reset",
    path: request_45password_45reset4DxUe558E2Meta?.path ?? "/auth/request-password-reset",
    meta: request_45password_45reset4DxUe558E2Meta || {},
    alias: request_45password_45reset4DxUe558E2Meta?.alias || [],
    redirect: request_45password_45reset4DxUe558E2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/auth/request-password-reset.vue").then(m => m.default || m)
  },
  {
    name: reset_45password_45confirmationRG2RExxdlkMeta?.name ?? "auth-reset-password-confirmation",
    path: reset_45password_45confirmationRG2RExxdlkMeta?.path ?? "/auth/reset-password-confirmation",
    meta: reset_45password_45confirmationRG2RExxdlkMeta || {},
    alias: reset_45password_45confirmationRG2RExxdlkMeta?.alias || [],
    redirect: reset_45password_45confirmationRG2RExxdlkMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/auth/reset-password-confirmation.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordBI6yvYngksMeta?.name ?? "auth-reset-password",
    path: reset_45passwordBI6yvYngksMeta?.path ?? "/auth/reset-password",
    meta: reset_45passwordBI6yvYngksMeta || {},
    alias: reset_45passwordBI6yvYngksMeta?.alias || [],
    redirect: reset_45passwordBI6yvYngksMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: settingsdy2kRmbIVeMeta?.name ?? "auth-settings",
    path: settingsdy2kRmbIVeMeta?.path ?? "/auth/settings",
    meta: settingsdy2kRmbIVeMeta || {},
    alias: settingsdy2kRmbIVeMeta?.alias || [],
    redirect: settingsdy2kRmbIVeMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/auth/settings.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93wINMVrUdXOMeta?.name ?? "auth-verify-email-userId-hash",
    path: _91hash_93wINMVrUdXOMeta?.path ?? "/auth/verify-email/:userId()/:hash()",
    meta: _91hash_93wINMVrUdXOMeta || {},
    alias: _91hash_93wINMVrUdXOMeta?.alias || [],
    redirect: _91hash_93wINMVrUdXOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/auth/verify-email/[userId]/[hash].vue").then(m => m.default || m)
  },
  {
    path: categoriesV6Lx1InbDKMeta?.path ?? "/categories",
    children: [
  {
    name: _91categoryId_93MUYEY2IL2kMeta?.name ?? "categories-categoryId",
    path: _91categoryId_93MUYEY2IL2kMeta?.path ?? ":categoryId()",
    meta: _91categoryId_93MUYEY2IL2kMeta || {},
    alias: _91categoryId_93MUYEY2IL2kMeta?.alias || [],
    redirect: _91categoryId_93MUYEY2IL2kMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/categories/[categoryId].vue").then(m => m.default || m)
  },
  {
    name: _91salesChannelId_93jcdNfLhyHQMeta?.name ?? "categories-create-salesChannelId",
    path: _91salesChannelId_93jcdNfLhyHQMeta?.path ?? "create/:salesChannelId()",
    meta: _91salesChannelId_93jcdNfLhyHQMeta || {},
    alias: _91salesChannelId_93jcdNfLhyHQMeta?.alias || [],
    redirect: _91salesChannelId_93jcdNfLhyHQMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/categories/create/[salesChannelId].vue").then(m => m.default || m)
  },
  {
    name: indexLbaC5b4OauMeta?.name ?? "categories",
    path: indexLbaC5b4OauMeta?.path ?? "",
    meta: indexLbaC5b4OauMeta || {},
    alias: indexLbaC5b4OauMeta?.alias || [],
    redirect: indexLbaC5b4OauMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/categories/index.vue").then(m => m.default || m)
  }
],
    name: categoriesV6Lx1InbDKMeta?.name ?? undefined,
    meta: categoriesV6Lx1InbDKMeta || {},
    alias: categoriesV6Lx1InbDKMeta?.alias || [],
    redirect: categoriesV6Lx1InbDKMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/categories.vue").then(m => m.default || m)
  },
  {
    name: codesi6k5OdZVGpMeta?.name ?? "discounts-discountId-codes",
    path: codesi6k5OdZVGpMeta?.path ?? "/discounts/:discountId()/codes",
    meta: codesi6k5OdZVGpMeta || {},
    alias: codesi6k5OdZVGpMeta?.alias || [],
    redirect: codesi6k5OdZVGpMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/discounts/[discountId]/codes.vue").then(m => m.default || m)
  },
  {
    name: indexwCJb95loJyMeta?.name ?? "discounts-discountId",
    path: indexwCJb95loJyMeta?.path ?? "/discounts/:discountId()",
    meta: indexwCJb95loJyMeta || {},
    alias: indexwCJb95loJyMeta?.alias || [],
    redirect: indexwCJb95loJyMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/discounts/[discountId]/index.vue").then(m => m.default || m)
  },
  {
    name: createofupGenh4oMeta?.name ?? "discounts-create",
    path: createofupGenh4oMeta?.path ?? "/discounts/create",
    meta: createofupGenh4oMeta || {},
    alias: createofupGenh4oMeta?.alias || [],
    redirect: createofupGenh4oMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/discounts/create.vue").then(m => m.default || m)
  },
  {
    name: index1iX6RBJy9wMeta?.name ?? "discounts",
    path: index1iX6RBJy9wMeta?.path ?? "/discounts",
    meta: index1iX6RBJy9wMeta || {},
    alias: index1iX6RBJy9wMeta?.alias || [],
    redirect: index1iX6RBJy9wMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/discounts/index.vue").then(m => m.default || m)
  },
  {
    path: enhancements3yKSJ3BV9IMeta?.path ?? "/enhancements",
    children: [
  {
    name: _91enhancementId_93N69xBqolMFMeta?.name ?? "enhancements-enhancementId",
    path: _91enhancementId_93N69xBqolMFMeta?.path ?? ":enhancementId()",
    meta: _91enhancementId_93N69xBqolMFMeta || {},
    alias: _91enhancementId_93N69xBqolMFMeta?.alias || [],
    redirect: _91enhancementId_93N69xBqolMFMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/enhancements/[enhancementId].vue").then(m => m.default || m)
  },
  {
    name: createDLGLkX4NdPMeta?.name ?? "enhancements-create",
    path: createDLGLkX4NdPMeta?.path ?? "create",
    meta: createDLGLkX4NdPMeta || {},
    alias: createDLGLkX4NdPMeta?.alias || [],
    redirect: createDLGLkX4NdPMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/enhancements/create.vue").then(m => m.default || m)
  },
  {
    name: indexyqkdGS8dntMeta?.name ?? "enhancements",
    path: indexyqkdGS8dntMeta?.path ?? "",
    meta: indexyqkdGS8dntMeta || {},
    alias: indexyqkdGS8dntMeta?.alias || [],
    redirect: indexyqkdGS8dntMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/enhancements/index.vue").then(m => m.default || m)
  }
],
    name: enhancements3yKSJ3BV9IMeta?.name ?? undefined,
    meta: enhancements3yKSJ3BV9IMeta || {},
    alias: enhancements3yKSJ3BV9IMeta?.alias || [],
    redirect: enhancements3yKSJ3BV9IMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/enhancements.vue").then(m => m.default || m)
  },
  {
    name: indexMm2ZM7LfDiMeta?.name ?? "index",
    path: indexMm2ZM7LfDiMeta?.path ?? "/",
    meta: indexMm2ZM7LfDiMeta || {},
    alias: indexMm2ZM7LfDiMeta?.alias || [],
    redirect: indexMm2ZM7LfDiMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitationId_93PhL1nSwbDuMeta?.name ?? "invitation-invitationId",
    path: _91invitationId_93PhL1nSwbDuMeta?.path ?? "/invitation/:invitationId()",
    meta: _91invitationId_93PhL1nSwbDuMeta || {},
    alias: _91invitationId_93PhL1nSwbDuMeta?.alias || [],
    redirect: _91invitationId_93PhL1nSwbDuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/invitation/[invitationId].vue").then(m => m.default || m)
  },
  {
    name: invalidcxFIIDEfnsMeta?.name ?? "invitation-invalid",
    path: invalidcxFIIDEfnsMeta?.path ?? "/invitation/invalid",
    meta: invalidcxFIIDEfnsMeta || {},
    alias: invalidcxFIIDEfnsMeta?.alias || [],
    redirect: invalidcxFIIDEfnsMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/invitation/invalid.vue").then(m => m.default || m)
  },
  {
    path: orderspokBprXfafMeta?.path ?? "/orders",
    children: [
  {
    name: _91orderId_93Dd4FVazpyRMeta?.name ?? "orders-orderId",
    path: _91orderId_93Dd4FVazpyRMeta?.path ?? ":orderId()",
    meta: _91orderId_93Dd4FVazpyRMeta || {},
    alias: _91orderId_93Dd4FVazpyRMeta?.alias || [],
    redirect: _91orderId_93Dd4FVazpyRMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/orders/[orderId].vue").then(m => m.default || m)
  },
  {
    name: indexZTkQzWDGkIMeta?.name ?? "orders",
    path: indexZTkQzWDGkIMeta?.path ?? "",
    meta: indexZTkQzWDGkIMeta || {},
    alias: indexZTkQzWDGkIMeta?.alias || [],
    redirect: indexZTkQzWDGkIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/orders/index.vue").then(m => m.default || m)
  }
],
    name: orderspokBprXfafMeta?.name ?? undefined,
    meta: orderspokBprXfafMeta || {},
    alias: orderspokBprXfafMeta?.alias || [],
    redirect: orderspokBprXfafMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/orders.vue").then(m => m.default || m)
  },
  {
    path: productsvUwbW9DgHqMeta?.path ?? "/products",
    children: [
  {
    name: indexXu9adEzwKOMeta?.name ?? "products-productId",
    path: indexXu9adEzwKOMeta?.path ?? ":productId()",
    meta: indexXu9adEzwKOMeta || {},
    alias: indexXu9adEzwKOMeta?.alias || [],
    redirect: indexXu9adEzwKOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/products/[productId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91variantId_93SzwQXnDlQ4Meta?.name ?? "products-productId-variants-variantId",
    path: _91variantId_93SzwQXnDlQ4Meta?.path ?? ":productId()/variants/:variantId()",
    meta: _91variantId_93SzwQXnDlQ4Meta || {},
    alias: _91variantId_93SzwQXnDlQ4Meta?.alias || [],
    redirect: _91variantId_93SzwQXnDlQ4Meta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/products/[productId]/variants/[variantId].vue").then(m => m.default || m)
  },
  {
    name: createKYH9TJufenMeta?.name ?? "products-productId-variants-create",
    path: createKYH9TJufenMeta?.path ?? ":productId()/variants/create",
    meta: createKYH9TJufenMeta || {},
    alias: createKYH9TJufenMeta?.alias || [],
    redirect: createKYH9TJufenMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/products/[productId]/variants/create.vue").then(m => m.default || m)
  },
  {
    name: _91type_935SaHedTheOMeta?.name ?? "products-create-type",
    path: _91type_935SaHedTheOMeta?.path ?? "create/:type()",
    meta: _91type_935SaHedTheOMeta || {},
    alias: _91type_935SaHedTheOMeta?.alias || [],
    redirect: _91type_935SaHedTheOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/products/create/[type].vue").then(m => m.default || m)
  },
  {
    name: createdPWMzbJzG6Meta?.name ?? "products-create-variants-create",
    path: createdPWMzbJzG6Meta?.path ?? "create/variants/create",
    meta: createdPWMzbJzG6Meta || {},
    alias: createdPWMzbJzG6Meta?.alias || [],
    redirect: createdPWMzbJzG6Meta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/products/create/variants/create.vue").then(m => m.default || m)
  },
  {
    name: indexX9E3n1vsapMeta?.name ?? "products",
    path: indexX9E3n1vsapMeta?.path ?? "",
    meta: indexX9E3n1vsapMeta || {},
    alias: indexX9E3n1vsapMeta?.alias || [],
    redirect: indexX9E3n1vsapMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/products/index.vue").then(m => m.default || m)
  }
],
    name: productsvUwbW9DgHqMeta?.name ?? undefined,
    meta: productsvUwbW9DgHqMeta || {},
    alias: productsvUwbW9DgHqMeta?.alias || [],
    redirect: productsvUwbW9DgHqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/products.vue").then(m => m.default || m)
  },
  {
    path: reportshCmlfPrGB1Meta?.path ?? "/reports",
    children: [
  {
    name: indexABfqqO6wyGMeta?.name ?? "reports",
    path: indexABfqqO6wyGMeta?.path ?? "",
    meta: indexABfqqO6wyGMeta || {},
    alias: indexABfqqO6wyGMeta?.alias || [],
    redirect: indexABfqqO6wyGMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/reports/index.vue").then(m => m.default || m)
  }
],
    name: reportshCmlfPrGB1Meta?.name ?? undefined,
    meta: reportshCmlfPrGB1Meta || {},
    alias: reportshCmlfPrGB1Meta?.alias || [],
    redirect: reportshCmlfPrGB1Meta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/reports.vue").then(m => m.default || m)
  },
  {
    path: settingslZuwzuWYJ0Meta?.path ?? "/settings",
    children: [
  {
    name: billingMRFCTtWjZ3Meta?.name ?? "settings-billing",
    path: billingMRFCTtWjZ3Meta?.path ?? "billing",
    meta: billingMRFCTtWjZ3Meta || {},
    alias: billingMRFCTtWjZ3Meta?.alias || [],
    redirect: billingMRFCTtWjZ3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    path: email_45domains6atHaIv86FMeta?.path ?? "email-domains",
    children: [
  {
    name: _91hostname_93QOBGLnSTZ9Meta?.name ?? "settings-email-domains-hostname",
    path: _91hostname_93QOBGLnSTZ9Meta?.path ?? ":hostname()",
    meta: _91hostname_93QOBGLnSTZ9Meta || {},
    alias: _91hostname_93QOBGLnSTZ9Meta?.alias || [],
    redirect: _91hostname_93QOBGLnSTZ9Meta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/email-domains/[hostname].vue").then(m => m.default || m)
  },
  {
    name: indexvP7FMhrJgqMeta?.name ?? "settings-email-domains",
    path: indexvP7FMhrJgqMeta?.path ?? "",
    meta: indexvP7FMhrJgqMeta || {},
    alias: indexvP7FMhrJgqMeta?.alias || [],
    redirect: indexvP7FMhrJgqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/email-domains/index.vue").then(m => m.default || m)
  }
],
    name: email_45domains6atHaIv86FMeta?.name ?? undefined,
    meta: email_45domains6atHaIv86FMeta || {},
    alias: email_45domains6atHaIv86FMeta?.alias || [],
    redirect: email_45domains6atHaIv86FMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/email-domains.vue").then(m => m.default || m)
  },
  {
    name: generalzRHj1DMZGUMeta?.name ?? "settings-general",
    path: generalzRHj1DMZGUMeta?.path ?? "general",
    meta: generalzRHj1DMZGUMeta || {},
    alias: generalzRHj1DMZGUMeta?.alias || [],
    redirect: generalzRHj1DMZGUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/general.vue").then(m => m.default || m)
  },
  {
    name: indexgLKkuUg2x3Meta?.name ?? "settings",
    path: indexgLKkuUg2x3Meta?.path ?? "",
    meta: indexgLKkuUg2x3Meta || {},
    alias: indexgLKkuUg2x3Meta?.alias || [],
    redirect: indexgLKkuUg2x3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: paypalZZkuduttlvMeta?.name ?? "settings-payment-gateways-connect-paypal",
    path: paypalZZkuduttlvMeta?.path ?? "payment-gateways/connect/paypal",
    meta: paypalZZkuduttlvMeta || {},
    alias: paypalZZkuduttlvMeta?.alias || [],
    redirect: paypalZZkuduttlvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/payment-gateways/connect/paypal.vue").then(m => m.default || m)
  },
  {
    name: stripeJA7eJVskJMMeta?.name ?? "settings-payment-gateways-connect-stripe",
    path: stripeJA7eJVskJMMeta?.path ?? "payment-gateways/connect/stripe",
    meta: stripeJA7eJVskJMMeta || {},
    alias: stripeJA7eJVskJMMeta?.alias || [],
    redirect: stripeJA7eJVskJMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/payment-gateways/connect/stripe.vue").then(m => m.default || m)
  },
  {
    name: indexzXE2qsy69rMeta?.name ?? "settings-payment-gateways",
    path: indexzXE2qsy69rMeta?.path ?? "payment-gateways",
    meta: indexzXE2qsy69rMeta || {},
    alias: indexzXE2qsy69rMeta?.alias || [],
    redirect: indexzXE2qsy69rMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/payment-gateways/index.vue").then(m => m.default || m)
  },
  {
    path: redeem_45locationsawD0PJp3TmMeta?.path ?? "redeem-locations",
    children: [
  {
    name: _91redeemLocationId_93yKsWNCbCaPMeta?.name ?? "settings-redeem-locations-redeemLocationId",
    path: _91redeemLocationId_93yKsWNCbCaPMeta?.path ?? ":redeemLocationId()",
    meta: _91redeemLocationId_93yKsWNCbCaPMeta || {},
    alias: _91redeemLocationId_93yKsWNCbCaPMeta?.alias || [],
    redirect: _91redeemLocationId_93yKsWNCbCaPMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/redeem-locations/[redeemLocationId].vue").then(m => m.default || m)
  },
  {
    name: createrEW6HHLqdKMeta?.name ?? "settings-redeem-locations-create",
    path: createrEW6HHLqdKMeta?.path ?? "create",
    meta: createrEW6HHLqdKMeta || {},
    alias: createrEW6HHLqdKMeta?.alias || [],
    redirect: createrEW6HHLqdKMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/redeem-locations/create.vue").then(m => m.default || m)
  },
  {
    name: indexkwe8VZKJSxMeta?.name ?? "settings-redeem-locations",
    path: indexkwe8VZKJSxMeta?.path ?? "",
    meta: indexkwe8VZKJSxMeta || {},
    alias: indexkwe8VZKJSxMeta?.alias || [],
    redirect: indexkwe8VZKJSxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/redeem-locations/index.vue").then(m => m.default || m)
  }
],
    name: redeem_45locationsawD0PJp3TmMeta?.name ?? undefined,
    meta: redeem_45locationsawD0PJp3TmMeta || {},
    alias: redeem_45locationsawD0PJp3TmMeta?.alias || [],
    redirect: redeem_45locationsawD0PJp3TmMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/redeem-locations.vue").then(m => m.default || m)
  },
  {
    path: userscBFy1d0MemMeta?.path ?? "users",
    children: [
  {
    name: _91userId_93xvSzuW5bUoMeta?.name ?? "settings-users-userId",
    path: _91userId_93xvSzuW5bUoMeta?.path ?? ":userId()",
    meta: _91userId_93xvSzuW5bUoMeta || {},
    alias: _91userId_93xvSzuW5bUoMeta?.alias || [],
    redirect: _91userId_93xvSzuW5bUoMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: emailBXfCz7IIqwMeta?.name ?? "settings-users-create-email",
    path: emailBXfCz7IIqwMeta?.path ?? "create/email",
    meta: emailBXfCz7IIqwMeta || {},
    alias: emailBXfCz7IIqwMeta?.alias || [],
    redirect: emailBXfCz7IIqwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/users/create/email.vue").then(m => m.default || m)
  },
  {
    name: usernameAFdgxoXa5kMeta?.name ?? "settings-users-create-username",
    path: usernameAFdgxoXa5kMeta?.path ?? "create/username",
    meta: usernameAFdgxoXa5kMeta || {},
    alias: usernameAFdgxoXa5kMeta?.alias || [],
    redirect: usernameAFdgxoXa5kMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/users/create/username.vue").then(m => m.default || m)
  },
  {
    name: indexgwtn5cfBPIMeta?.name ?? "settings-users",
    path: indexgwtn5cfBPIMeta?.path ?? "",
    meta: indexgwtn5cfBPIMeta || {},
    alias: indexgwtn5cfBPIMeta?.alias || [],
    redirect: indexgwtn5cfBPIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/users/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitationId_93joJAxk8iObMeta?.name ?? "settings-users-invitations-invitationId",
    path: _91invitationId_93joJAxk8iObMeta?.path ?? "invitations/:invitationId()",
    meta: _91invitationId_93joJAxk8iObMeta || {},
    alias: _91invitationId_93joJAxk8iObMeta?.alias || [],
    redirect: _91invitationId_93joJAxk8iObMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/users/invitations/[invitationId].vue").then(m => m.default || m)
  },
  {
    name: indexGaM0PSVv1bMeta?.name ?? "settings-users-invitations",
    path: indexGaM0PSVv1bMeta?.path ?? "invitations",
    meta: indexGaM0PSVv1bMeta || {},
    alias: indexGaM0PSVv1bMeta?.alias || [],
    redirect: indexGaM0PSVv1bMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/users/invitations/index.vue").then(m => m.default || m)
  }
],
    name: userscBFy1d0MemMeta?.name ?? undefined,
    meta: userscBFy1d0MemMeta || {},
    alias: userscBFy1d0MemMeta?.alias || [],
    redirect: userscBFy1d0MemMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/users.vue").then(m => m.default || m)
  }
],
    name: settingslZuwzuWYJ0Meta?.name ?? undefined,
    meta: settingslZuwzuWYJ0Meta || {},
    alias: settingslZuwzuWYJ0Meta?.alias || [],
    redirect: settingslZuwzuWYJ0Meta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings.vue").then(m => m.default || m)
  },
  {
    path: storesmaIhalQqooMeta?.path ?? "/stores",
    children: [
  {
    name: _91page_93Ai3wtoRJpQMeta?.name ?? "stores-salesChannelId-emails-page",
    path: _91page_93Ai3wtoRJpQMeta?.path ?? ":salesChannelId()/emails/:page()",
    meta: _91page_93Ai3wtoRJpQMeta || {},
    alias: _91page_93Ai3wtoRJpQMeta?.alias || [],
    redirect: _91page_93Ai3wtoRJpQMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/[salesChannelId]/emails/[page].vue").then(m => m.default || m)
  },
  {
    name: indexvN9O1GV0KqMeta?.name ?? "stores-salesChannelId-emails",
    path: indexvN9O1GV0KqMeta?.path ?? ":salesChannelId()/emails",
    meta: indexvN9O1GV0KqMeta || {},
    alias: indexvN9O1GV0KqMeta?.alias || [],
    redirect: indexvN9O1GV0KqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/[salesChannelId]/emails/index.vue").then(m => m.default || m)
  },
  {
    name: faq5kWEiV8EKiMeta?.name ?? "stores-salesChannelId-faq",
    path: faq5kWEiV8EKiMeta?.path ?? ":salesChannelId()/faq",
    meta: faq5kWEiV8EKiMeta || {},
    alias: faq5kWEiV8EKiMeta?.alias || [],
    redirect: faq5kWEiV8EKiMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/[salesChannelId]/faq.vue").then(m => m.default || m)
  },
  {
    name: indexN3mLogBYmaMeta?.name ?? "stores-salesChannelId",
    path: indexN3mLogBYmaMeta?.path ?? ":salesChannelId()",
    meta: indexN3mLogBYmaMeta || {},
    alias: indexN3mLogBYmaMeta?.alias || [],
    redirect: indexN3mLogBYmaMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/[salesChannelId]/index.vue").then(m => m.default || m)
  },
  {
    name: privacyf9pEyNN8Z1Meta?.name ?? "stores-salesChannelId-privacy",
    path: privacyf9pEyNN8Z1Meta?.path ?? ":salesChannelId()/privacy",
    meta: privacyf9pEyNN8Z1Meta || {},
    alias: privacyf9pEyNN8Z1Meta?.alias || [],
    redirect: privacyf9pEyNN8Z1Meta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/[salesChannelId]/privacy.vue").then(m => m.default || m)
  },
  {
    name: termsFdakOLnHINMeta?.name ?? "stores-salesChannelId-terms",
    path: termsFdakOLnHINMeta?.path ?? ":salesChannelId()/terms",
    meta: termsFdakOLnHINMeta || {},
    alias: termsFdakOLnHINMeta?.alias || [],
    redirect: termsFdakOLnHINMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/[salesChannelId]/terms.vue").then(m => m.default || m)
  },
  {
    name: _91page_938roXampeqIMeta?.name ?? "stores-create-type-emails-page",
    path: _91page_938roXampeqIMeta?.path ?? "create/:type()/emails/:page()",
    meta: _91page_938roXampeqIMeta || {},
    alias: _91page_938roXampeqIMeta?.alias || [],
    redirect: _91page_938roXampeqIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/create/[type]/emails/[page].vue").then(m => m.default || m)
  },
  {
    name: indexCo4HWlaguYMeta?.name ?? "stores-create-type-emails",
    path: indexCo4HWlaguYMeta?.path ?? "create/:type()/emails",
    meta: indexCo4HWlaguYMeta || {},
    alias: indexCo4HWlaguYMeta?.alias || [],
    redirect: indexCo4HWlaguYMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/create/[type]/emails/index.vue").then(m => m.default || m)
  },
  {
    name: faqHWj8UmEqT3Meta?.name ?? "stores-create-type-faq",
    path: faqHWj8UmEqT3Meta?.path ?? "create/:type()/faq",
    meta: faqHWj8UmEqT3Meta || {},
    alias: faqHWj8UmEqT3Meta?.alias || [],
    redirect: faqHWj8UmEqT3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/create/[type]/faq.vue").then(m => m.default || m)
  },
  {
    name: indexTEH9zmYUhtMeta?.name ?? "stores-create-type",
    path: indexTEH9zmYUhtMeta?.path ?? "create/:type()",
    meta: indexTEH9zmYUhtMeta || {},
    alias: indexTEH9zmYUhtMeta?.alias || [],
    redirect: indexTEH9zmYUhtMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/create/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: privacyByP9mSA6KXMeta?.name ?? "stores-create-type-privacy",
    path: privacyByP9mSA6KXMeta?.path ?? "create/:type()/privacy",
    meta: privacyByP9mSA6KXMeta || {},
    alias: privacyByP9mSA6KXMeta?.alias || [],
    redirect: privacyByP9mSA6KXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/create/[type]/privacy.vue").then(m => m.default || m)
  },
  {
    name: termsaUb4XBqvwiMeta?.name ?? "stores-create-type-terms",
    path: termsaUb4XBqvwiMeta?.path ?? "create/:type()/terms",
    meta: termsaUb4XBqvwiMeta || {},
    alias: termsaUb4XBqvwiMeta?.alias || [],
    redirect: termsaUb4XBqvwiMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/create/[type]/terms.vue").then(m => m.default || m)
  },
  {
    name: indexgwyxZtsxIHMeta?.name ?? "stores",
    path: indexgwyxZtsxIHMeta?.path ?? "",
    meta: indexgwyxZtsxIHMeta || {},
    alias: indexgwyxZtsxIHMeta?.alias || [],
    redirect: indexgwyxZtsxIHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/index.vue").then(m => m.default || m)
  },
  {
    name: interstitialmvRZM7J5BAMeta?.name ?? "stores-interstitial",
    path: interstitialmvRZM7J5BAMeta?.path ?? "interstitial",
    meta: interstitialmvRZM7J5BAMeta || {},
    alias: interstitialmvRZM7J5BAMeta?.alias || [],
    redirect: interstitialmvRZM7J5BAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/interstitial.vue").then(m => m.default || m)
  }
],
    name: storesmaIhalQqooMeta?.name ?? undefined,
    meta: storesmaIhalQqooMeta || {},
    alias: storesmaIhalQqooMeta?.alias || [],
    redirect: storesmaIhalQqooMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores.vue").then(m => m.default || m)
  },
  {
    name: settingsOaNJChXSSfMeta?.name ?? "terminal-auth-settings",
    path: settingsOaNJChXSSfMeta?.path ?? "/terminal/auth/settings",
    meta: settingsOaNJChXSSfMeta || {},
    alias: settingsOaNJChXSSfMeta?.alias || [],
    redirect: settingsOaNJChXSSfMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/terminal/auth/settings.vue").then(m => m.default || m)
  },
  {
    name: indexr0ZYRS1BiLMeta?.name ?? "terminal",
    path: indexr0ZYRS1BiLMeta?.path ?? "/terminal",
    meta: indexr0ZYRS1BiLMeta || {},
    alias: indexr0ZYRS1BiLMeta?.alias || [],
    redirect: indexr0ZYRS1BiLMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/terminal/index.vue").then(m => m.default || m)
  },
  {
    name: _91reference_939tx0bUJDl4Meta?.name ?? "terminal-redeem-reference",
    path: _91reference_939tx0bUJDl4Meta?.path ?? "/terminal/redeem/:reference()",
    meta: _91reference_939tx0bUJDl4Meta || {},
    alias: _91reference_939tx0bUJDl4Meta?.alias || [],
    redirect: _91reference_939tx0bUJDl4Meta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/terminal/redeem/[reference].vue").then(m => m.default || m)
  },
  {
    name: confirmationugEPFNdj5fMeta?.name ?? "terminal-redeem-confirmation",
    path: confirmationugEPFNdj5fMeta?.path ?? "/terminal/redeem/confirmation",
    meta: confirmationugEPFNdj5fMeta || {},
    alias: confirmationugEPFNdj5fMeta?.alias || [],
    redirect: confirmationugEPFNdj5fMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/terminal/redeem/confirmation.vue").then(m => m.default || m)
  },
  {
    name: indexLSyr03I8CQMeta?.name ?? "terminal-redeem",
    path: indexLSyr03I8CQMeta?.path ?? "/terminal/redeem",
    meta: indexLSyr03I8CQMeta || {},
    alias: indexLSyr03I8CQMeta?.alias || [],
    redirect: indexLSyr03I8CQMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/terminal/redeem/index.vue").then(m => m.default || m)
  },
  {
    name: _91redeemableId_93Wa2AUKs3QtMeta?.name ?? "terminal-redeem-voucher-reference-redeemableId",
    path: _91redeemableId_93Wa2AUKs3QtMeta?.path ?? "/terminal/redeem/voucher/:reference()/:redeemableId()",
    meta: _91redeemableId_93Wa2AUKs3QtMeta || {},
    alias: _91redeemableId_93Wa2AUKs3QtMeta?.alias || [],
    redirect: _91redeemableId_93Wa2AUKs3QtMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/terminal/redeem/voucher/[reference]/[redeemableId].vue").then(m => m.default || m)
  },
  {
    name: indexCKqzUDBLwKMeta?.name ?? "terminal-redeem-voucher-reference",
    path: indexCKqzUDBLwKMeta?.path ?? "/terminal/redeem/voucher/:reference()",
    meta: indexCKqzUDBLwKMeta || {},
    alias: indexCKqzUDBLwKMeta?.alias || [],
    redirect: indexCKqzUDBLwKMeta?.redirect || undefined,
    component: () => import("/home/runner/work/dashboard/dashboard/pages/terminal/redeem/voucher/[reference]/index.vue").then(m => m.default || m)
  }
]