/**
 * Hide the submenu when changing routes.
 *
 * If it is needed, the new page component will set it.
 */
export default defineNuxtRouteMiddleware((to, from): void => {
    // If the path is changing (i.e. not just the query parameters, like when sorting a table)
    if (to.path !== from.path) {
        // Hide the submenu
        useCommonStore().hideSubmenu();
    }
});
