<script setup lang="ts">
import type { NuxtError } from '#app';
import { homePath } from '~/utils/routes';

/* Props */

const props = defineProps<{
    error: NuxtError;
}>();

/* Component variables */

/**
 * Is the error a 404 Not Found error.
 */
const isNotFoundError: boolean = props.error.statusCode === 404;

/* Component methods */

/**
 * Refresh the page.
 */
const refreshPage = (): void => {
    window.location.reload();
};
</script>

<template>
    <div class="md:p-16 lg:p-20 xl:p-12 h-dvh">
        <div
            class="py-10 px-6 min-[430px]:py-16 min-[430px]:px-8 text-center bg-white h-full rounded-xl flex flex-col justify-center items-center gap-y-7 md:gap-y-16 text-center md:py-16 md:px-12 lg:px-[4.5rem] lg:py-24 lg:gap-y-24 xl:gap-y-8">
            <SvgErrorArt class="max-w-full md:w-[450px] lg:w-[644px] xl:w-[360px] h-auto"/>

            <div class="contents md:flex flex-col gap-y-9 xl:gap-y-6">
                <h1 class="text-2xl min-[430px]:text-3xl">
                    {{
                        isNotFoundError ? 'Uh oh! 🤦 Page not found' : 'Ooops! 🤦 Something went wrong'
                    }}
                </h1>

                <p class="min-[430px]:text-sm leading-5 min-[430px]:leading-6 text-xs">
                    <template v-if="isNotFoundError">
                        We can't seem to find the page you're looking for. Are you sure the URL is
                        correct?

                        <br>
                        <br class="md:hidden">

                        Try going back to the previous page, or visit the

                        <a :href="helpUrl('')" target="_blank" class="underline">
                            {{ useCommonStore().partner.name }} Help Centre</a>

                        for more help and information.
                    </template>

                    <template v-else>
                        We're currently making improvements to our system, so you may be getting
                        more
                        errors than usual.

                        <br>
                        <br class="md:hidden">

                        Things will be working as they should be soon. In the meantime, please
                        refresh
                        the page.
                    </template>
                </p>

                <div
                    class="flex flex-col gap-y-4 gap-x-5 w-full md:gap-y-5 xl:flex-row justify-center">
                    <template v-if="isNotFoundError">
                        <button type="button" class="button button-outline-primary xl:w-40"
                                @click="useRouter().back()">
                            Go back
                        </button>

                        <NuxtLink :to="homePath()" class="button button-primary xl:w-40">
                            Return home
                        </NuxtLink>
                    </template>

                    <template v-else>
                        <NuxtLink :to="homePath()" class="button button-outline-primary xl:w-40">
                            Return home
                        </NuxtLink>

                        <button type="button" class="button button-primary xl:w-40"
                                @click="refreshPage">
                            Refresh page
                        </button>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    /* 768px */
    @media screen and (width >= 48em) {
        .button {
            height: 3rem;
        }
    }

    /* 1280px */
    @media screen and (width >= 80em) {
        .button {
            height: 2.5rem;
        }
    }
</style>
